<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">
        Эрэмбийн жагсаалт
      </h3>
      <div class="action-section">
        <el-button
          type="success"
          size="mini"
          icon="el-icon-plus"
          @click="sendAdd = true"
          >Нэмэх</el-button
        >
      </div>
    </div>
    <el-row :gutter="0">
      <el-col :span="24" :offset="0">
        <div class="panel payments-container">
          <div align="right">
            <el-input
              style="width: 200px;"
              prefix-icon="el-icon-search"
              v-model="search"
              size="mini"
              placeholder="Хайх суртчилгааны нэр"
            />
          </div>
          <el-table
            :data="
              sortTypesList.filter(
                data =>
                  !search ||
                  data.name_mon.toLowerCase().includes(search.toLowerCase())
              )
            "
          >
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column prop="img" label="Зураг" width="150px">
              <template slot-scope="scope">
                <el-image
                  :src="scope.row.img_url"
                  style="width: 50px;"
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column prop="name_mon" label="Монгол нэр">
            </el-table-column>
            <el-table-column prop="name_eng" label="Англи нэр">
            </el-table-column>
            <el-table-column prop="sort" label="Эрэмбэ"> </el-table-column>
            <el-table-column
              prop="is_active"
              label="Төлөв"
              width="200"
              :filters="[
                { text: 'Идэвхтэй', value: true },
                { text: 'Идэвхгүй', value: false }
              ]"
              :filter-method="filterStatusTag"
            >
              <template slot-scope="scope">
                <el-tag v-if="scope.row.is_active == true" type="success">
                  Идэвхтэй
                </el-tag>
                <el-tag v-if="scope.row.is_active == false" type="info">
                  Идэвхгүй
                </el-tag>
              </template>
            </el-table-column>

            <el-table-column
              label="Үйлдэл"
              width="150px"
              v-if="!mArrayIncludes(role, ['client_care', 'other'])"
            >
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  @click="showEdit(scope.row)"
                  round
                ></el-button>
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-delete-solid"
                  @click="deleteSortTypes(scope.row.id)"
                  round
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      :title="'Эрэмбийн жагсаалт засах'"
      :visible.sync="sendEdit"
      @close="closeProgress()"
      width="40%"
      top="100px"
      class="process-dialog"
    >
      <div class="panel">
        <el-form
          :model="sortTypesEditData"
          :rules="rules"
          ref="sortTypesEditData"
          label-width="220px"
          class="demo-ruleForm"
        >
          <el-form-item label="Эрэмбийн монгол нэр" prop="name_mon">
            <el-input
              style="width:250px"
              size="mini"
              v-model="sortTypesEditData.name_mon"
              placeholder="Эрэмбийн нэр оруулна уу"
            ></el-input>
          </el-form-item>
          <el-form-item label="Эрэмбийн англи нэр" prop="name_eng">
            <el-input
              style="width:250px"
              size="mini"
              v-model="sortTypesEditData.name_eng"
              placeholder="Эрэмбийн англи нэр оруулна уу"
            ></el-input>
          </el-form-item>
          <el-form-item label="Өнгө" prop="color">
            <el-color-picker
              v-model="sortTypesEditData.color"
            ></el-color-picker>
          </el-form-item>
          <el-form-item label="Эрэмбийн зураг" prop="img">
            <el-input
              type="url"
              style="width:250px"
              size="mini"
              v-model="sortTypesEditData.img_url"
              placeholder="Эрэмбийн зурагны url оруулна уу"
            ></el-input>
          </el-form-item>
          <el-form-item label="Статус" prop="is_active">
            <el-switch v-model="sortTypesEditData.is_active"></el-switch>
          </el-form-item>
          <el-form-item label="Эрэмбэ" prop="sort">
            <el-input-number
              :precision="2"
              :step="0.1"
              :min="1"
              size="mini"
              v-model="sortTypesEditData.sort"
            ></el-input-number>
          </el-form-item>
          <el-button
            type="primary"
            style="margin-right: 20;"
            @click="saveEdit(sortTypesEditData)"
            >Нэмэх</el-button
          >
          <el-button @click="sendEdit = false">
            Буцах
          </el-button>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog
      :title="'Эрэмбийн жагсаалт нэмэх'"
      :visible.sync="sendAdd"
      @close="close()"
      width="40%"
      top="100px"
      class="process-dialog"
    >
      <div class="panel">
        <el-form
          :model="sortTypesAddData"
          :rules="rulesAddData"
          ref="sortTypesAddData"
          label-width="220px"
          class="demo-ruleForm"
        >
          <el-form-item label="Эрэмбийн монгол нэр" prop="name_mon">
            <el-input
              style="width:250px"
              size="mini"
              v-model="sortTypesAddData.name_mon"
              placeholder="Эрэмбийн нэр оруулна уу"
            ></el-input>
          </el-form-item>
          <el-form-item label="Эрэмбийн англи нэр" prop="name_eng">
            <el-input
              style="width:250px"
              size="mini"
              v-model="sortTypesAddData.name_eng"
              placeholder="Эрэмбийн англи нэр оруулна уу"
            ></el-input>
          </el-form-item>
          <el-form-item label="Өнгө" prop="color">
            <el-color-picker v-model="sortTypesAddData.color"></el-color-picker>
          </el-form-item>
          <el-form-item label="Эрэмбийн зураг" prop="img">
            <el-input
              type="url"
              style="width:250px"
              size="mini"
              v-model="sortTypesAddData.img_url"
              placeholder="Эрэмбийн зурагны url оруулна уу"
            ></el-input>
          </el-form-item>
          <el-form-item label="Статус" prop="is_active">
            <el-switch v-model="sortTypesAddData.is_active"></el-switch>
          </el-form-item>
          <el-form-item label="Эрэмбэ" prop="sort">
            <el-input-number
              :precision="2"
              :step="0.1"
              :min="1"
              size="mini"
              v-model="sortTypesAddData.sort"
            ></el-input-number>
          </el-form-item>
          <el-button
            type="primary"
            style="margin-right: 20;"
            @click="saveAdd(sortTypesAddData)"
            >Нэмэх</el-button
          >
          <el-button @click="sendAdd = false">
            Буцах
          </el-button>
        </el-form>
      </div>
    </el-dialog>
    <!-- <EditSortTypes
      :sortTypesEditData="sortTypesEditData"
      :sendEdit="sendEdit"
      :closeProgress="closeProgress"
    ></EditSortTypes> -->
  </div>
</template>

<script>
import service from "@/helpers/services.js";
// import EditSortTypes from "./EditSortTypes.vue";
import { getGroups } from "../utils/auth";
import { arrayIncludes } from "../helpers/helper";
export default {
  mounted() {
    this.getSortTypeList();
    getGroups().then(data => {
      this.role = data;
    });
  },
  //   components: { EditSortTypes },
  data() {
    return {
      search: "",
      sortTypesList: [],
      servicesList: [],
      sortTypesAddData: {
        name_mon: "",
        name_eng: "",
        color: "",
        img_url: "",
        is_active: false,
        sort: 1
      },
      sendAdd: false,
      sortTypesEditData: {},
      sendEdit: false,
      sendDeleteItem: {
        id: 0
      },
      role: [],
      rulesAddData: {
        name_mon: [
          {
            required: true,
            message: "Та Монгол нэрээ оруулна уу",
            trigger: ["blur", "change"]
          }
        ],
        name_eng: [
          {
            required: true,
            message: "Та Англи нэрээ оруулна уу",
            trigger: ["blur", "change"]
          }
        ]
      },
      rules: {
        name_mon: [
          {
            required: true,
            message: "Та Монгол нэрээ оруулна уу",
            trigger: ["blur", "change"]
          }
        ],
        name_eng: [
          {
            required: true,
            message: "Та Англи нэрээ оруулна уу",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  methods: {
    closeProgress() {
      this.sortTypesEditData = {};
      this.getSortTypeList();
      this.sendEdit = false;
    },
    close() {
      this.sortTypesAddData = {};
      this.getSortTypeList();
      this.sendAdd = false;
    },
    showEdit(data) {
      this.sortTypesEditData = Object.assign({}, data);
      this.sendEdit = true;
    },
    filterStatusTag(value, row) {
      return row.is_active === value;
    },
    deleteSortTypes(id) {
      this.$confirm("Энэ категори устгахдаа итгэлтэй байна уу?", "Анхаар", {
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        type: "warning"
      })
        .then(() => {
          service.deleteSortType(id).then(res => {
            if (res.data.status === "success") {
              this.$notify({
                title: "Амжилттай",
                message: "Эрэмбийн мэдээлэл устгагдлаа",
                type: "success"
              });
              this.getSortTypeList();
            } else if (res.data.status === "unsuccess") {
              this.$notify({
                title: "Амжилтгүй",
                message:
                  "Эрэмбийн мэдээлэл устгахад алдаа гарлаа " +
                  " " +
                  res.error.message,
                type: "warning"
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Буцлаа"
          });
        });
    },
    getSortTypeList() {
      service.getAllSortTypes().then(res => {
        this.sortTypesList = res.data.data;
      });
    },
    mArrayIncludes(base_array, searchArray) {
      return arrayIncludes(base_array, searchArray);
    },
    saveAdd(data) {
      this.$refs.sortTypesAddData.validate(valid => {
        if (valid) {
          const payload = {
            color: data.color,
            img: data.img_url,
            is_active: data.is_active,
            name_mon: data.name_mon,
            name_eng: data.name_eng,
            sort: data.sort
          };
          service.createSortType(payload).then(response => {
            if (response.data.data.status === "success") {
              this.$notify({
                title: "Амжилттай",
                message: "Эрэмбийн мэдээлэл нэмэгдлээ",
                type: "success"
              });
              this.close();
            } else if (response.data.data.status === "unsuccess") {
              this.$notify({
                title: "Амжилтгүй",
                message:
                  "Эрэмбийн мэдээлэл засахад алдаа гарлаа " +
                  " " +
                  response.error.message,
                type: "warning"
              });
            }
          });
        } else {
          this.$notify({
            title: "Анхааруулга",
            message: "Зарим талбарыг бөглөөгүй байна.",
            type: "warning"
          });
          return false;
        }
      });
    },
    saveEdit(data) {
      this.$refs.sortTypesEditData.validate(valid => {
        if (valid) {
          this.sortTypesEditData.count = 0;
          const payload = {
            color: data.color,
            img: data.img_url,
            is_active: data.is_active,
            name_mon: data.name_mon,
            name_eng: data.name_eng,
            sort: data.sort
          };
          service.updateSortType(data.id, payload).then(response => {
            if (response.data.status === "success") {
              this.$notify({
                title: "Амжилттай",
                message: "Эрэмбийн мэдээлэл засагдлаа",
                type: "success"
              });
              this.closeProgress();
            } else if (response.data.status === "unsuccess") {
              this.$notify({
                title: "Амжилтгүй",
                message:
                  "Эрэмбийн мэдээлэл засахад алдаа гарлаа " +
                  " " +
                  response.error.message,
                type: "warning"
              });
            } else if (response.status === "error") {
              this.$notify({
                title: "Амжилтгүй",
                message: response.error.message,
                type: "error"
              });
            }
          });
        } else {
          this.$notify({
            title: "Анхааруулга",
            message: "Зарим талбарыг бөглөөгүй байна.",
            type: "warning"
          });
          return false;
        }
      });
    }
  }
};
</script>
